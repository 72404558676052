.bg-tertiary,
.bg-fe-linen-200 {
  @apply bg-linen-200;
}

.sm\:bg-tertiary,
.sm\:bg-fe-linen-200 {
  @apply sm:bg-linen-200;
}

.wings-tertiary,
.wings-fe-linen-200 {
  @apply wings-linen-200;
}

.sm\:wings-tertiary,
.sm\:wings-fe-linen-200 {
  @apply sm:wings-linen-200;
}

.bg-tertiary-darker,
.bg-fe-linen-300 {
  @apply bg-linen-300;
}

.sm\:bg-tertiary-darker,
.sm\:bg-fe-linen-300 {
  @apply sm:bg-linen-300;
}

.wings-tertiary-darker,
.wings-fe-linen-300 {
  @apply wings-linen-300;
}

.sm\:wings-tertiary-darker,
.sm\:wings-fe-linen-300 {
  @apply sm:wings-linen-300;
}

.bg-secondary,
.bg-fe-dusty-pink-150,
.bg-fe-dusty-pink {
  @apply bg-dusty-pink;
}

.sm\:bg-secondary,
.sm\:bg-fe-dusty-pink-150,
.sm\:bg-fe-dusty-pink {
  @apply sm:bg-dusty-pink;
}

.wings-secondary,
.wings-fe-dusty-pink-150,
.wings-fe-dusty-pink {
  @apply wings-dusty-pink;
}

.sm\:wings-secondary,
.sm\:wings-fe-dusty-pink-150,
.sm\:wings-fe-dusty-pink {
  @apply sm:wings-dusty-pink;
}

.bg-accent-1,
.bg-fe-royal-blue-700 {
  @apply bg-royal-blue;
}

.sm\:bg-accent-1,
.sm\:bg-fe-royal-blue-700 {
  @apply sm:bg-royal-blue;
}

.wings-accent-1,
.wings-fe-royal-blue-700 {
  @apply wings-royal-blue;
}

.sm\:wings-accent-1,
.sm\:wings-fe-royal-blue-700 {
  @apply sm:wings-royal-blue;
}

.bg-accent-2,
.bg-fe-gold-400 {
  @apply bg-gold;
}

.sm\:bg-accent-2,
.sm\:bg-fe-gold-400 {
  @apply sm:bg-gold;
}

.wings-accent-2,
.wings-fe-gold-400 {
  @apply wings-gold;
}

.sm\:wings-accent-2,
.sm\:wings-fe-gold-400 {
  @apply sm:wings-gold;
}

.text-fe-summer-red-600 {
  @apply text-summer-red-600;
}

.sm\:text-fe-summer-red-600 {
  @apply sm:text-summer-red-600;
}
